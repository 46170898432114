import React, { useEffect } from "react";
import Marquee from "react-easy-marquee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import AnimatedText from "react-animated-text-content";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section>
      <header>
        <Marquee className="py-lg-5 py-0" duration="10000" align="center">
          <div className="services-marque-header">
            <FontAwesomeIcon className="rotate mx-3" icon={faAsterisk} /> Get In Touch With Us
          </div>

          <div className="services-marque-header">
            <FontAwesomeIcon className="rotate mx-3" icon={faAsterisk} /> Get In Touch With Us
          </div>
        </Marquee>

        <div className="row justify-content-center p-0 m-0">
          <div className="col-lg-6 col-12">
            <AnimatedText
              type="words" // animate words or chars
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.1,
                ease: "ease-in-out",
              }}
              animationType="float"
              interval={0.06}
              duration={0.1}
              tag="p"
              className="animated-paragraph text-center"
              includeWhiteSpaces
              threshold={0.1}
            >
              We will love to hear from you, send us a message and your prayer request.
            </AnimatedText>
          </div>
        </div>
      </header>

      <section className="py-5">
        <div className="container">
          <div className="row gap-lg-0 gap-3">
            <div className="col-lg">
              <h5 className="section-title text-capitalize">Locations</h5>
              <address>
              20 Adebiyi Street, off lawani Street, Akoka Yaba..
              </address>
            </div>
            <div className="col-lg">
              <h5 className="section-title text-capitalize">Call or Write Us</h5>
              <div>
                <a className="section-link" href="tel:+2348082907215">
                  +2348082907215
                </a>
                ,{" "}
                <a className="section-link" href="tel:+2347084030632">
                  +2347084030632
                </a>
              </div>
              <div>
                <a className="section-link" href="mailto:onebodychurchng@gmail.com">
                  onebodychurchng@gmail.com
                </a>
              </div>
            </div>
            <div className="col-lg">
              <h5 className="section-title text-capitalize">Opening Hours</h5>
              <ul>
                <li>Sunday Service: 08:00am - 10:30am</li>
                <li>Wednesday Mid-Week: 05:30pm - 7:00pm</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Contact;
