import React from "react";

const SectionTwo = () => {
  return (
    <section className="py-md-5 py-3">
      <div className="container">
        <div className="row">
          <div className="col-lg">
          <div className="section-title">Love Vision Academy</div>
          </div>
          <div className="col-lg-6">
            <p>
              The Love Vision Academy is a transformative eight-module journey
              designed to deepen your understanding of God’s vision for His
              Church. This program equips you with Christ-centered knowledge and
              practical insights to serve with purpose, passion, and love.
            </p>

            <p>
              <strong>Our Mission: </strong>
              <br />
              To help you gain a profound understanding of God’s Word and learn
              how to apply it in your daily life. The program is facilitated
              using The Love Vision, a book by Pastor Taiwo Oyemade, the Church
              pastor of Onebody Church. This foundational work embodies the
              culture of a Gospel-centered church and serves as a guide to
              living out Jesus' teachings.
            </p>

            <div>
              <p>
                <strong>What to Expect Each Week:</strong>
              </p>
              <ul>
                <li>In-depth study of course materials</li>
                <li>Meaningful discussions to deepen your understanding</li>
                <li>Engaging assessments to reinforce your learning</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionTwo;
