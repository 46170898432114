// css
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import { Routes, Route } from "react-router-dom";
// layout
import MainLayout from "./layouts/MainLayout";
// pages
import Home from "./pages/Home";
import About from "./pages/About";
import LOVA from "./pages/LOVA";
import Contact from "./pages/Contact";
import Mixlr from "./pages/Mixlr";
import NotFound from "./pages/404";
import LordsTable from "./pages/LordsTable";
import Give from "./pages/Give";
// import Charity from "./pages/Charity";

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/love-vision" element={<LOVA />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/live" element={<Mixlr />} />
        <Route path="/lords-table" element={<LordsTable />} />
        <Route path="/give" element={<Give />} />
        {/* <Route path="/charity" element={<Charity/>} /> */}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
