import React from "react";
import Marquee from "react-easy-marquee";
// import Passion from "../../assets/images/passion.png";
// import HpNight from "../../assets/images/hope-night.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Asterisk from "../../assets/images/Asterisk.svg";

const responsive = {
  allScreens: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

let passionService = `https://i.ibb.co/D9Tcjwn/ND2-A8540-copy.jpg`;
let hopeNight = `https://i.ibb.co/rGzHwPn/Whats-App-Image-2024-05-26-at-18-51-25.jpg`;

const Services = () => {
  return (
    <section className="pb-3">
      <header className="service-marquee-wrapper">
        <Marquee className="py-lg-5 py-0" duration="10000" align="center">
          <div className="services-marque-header">
            <div className="d-flex justify-content-evenly align-items-center">
              <img src={Asterisk} className="rotate" alt="" />
              <span>Our Services</span>
            </div>
          </div>

          <div className="services-marque-header">
            <div className="d-flex justify-content-evenly align-items-center">
              <img src={Asterisk} className="rotate" alt="" />
              <span>Our Services</span>
            </div>
          </div>
        </Marquee>

        <Marquee
          className="py-lg-5 py-0"
          duration="10000"
          align="center"
          reverse="true"
        >
          <div className="services-marque-header">
            <div className="d-flex justify-content-evenly align-items-center">
              <img src={Asterisk} className="rotate" alt="" />
              <span>Our Services</span>
            </div>
          </div>

          <div className="services-marque-header">
            <div className="d-flex justify-content-evenly align-items-center">
              <img src={Asterisk} className="rotate" alt="" />
              <span>Our Services</span>
            </div>
          </div>
        </Marquee>
      </header>

      <div className="container">
        <div className="row justify-content-center align-items-center gap-5 mt-5">
          <div className="col-lg-6">
            <Carousel
              responsive={responsive}
              infinite={false}
              autoPlay={false}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              transitionDuration={3000}
              focusOnSelect={true}
              swipeable={true}
            >
              <div
                style={{ backgroundImage: `url(${passionService})` }}
                className={`service-pic-frame`}
              ></div>
              <div
                style={{ backgroundImage: `url(${hopeNight})` }}
                className={`service-pic-frame`}
              ></div>
            </Carousel>
          </div>

          <div className="col-lg-5 col-12">
            <div>
              <h2 className="service-title">Passion Service</h2>
              <p className="service-time">Sunday Morning Service 9:00AM</p>
            </div>

            <div>
              <h2 className="service-title">Hope Nights</h2>
              <p>
                <span className="service-time">Wednesday Nights 6:00PM</span>
              </p>
            </div>
            <div>
              <h2 className="service-title">Address:</h2>
              <p>
                <span className="service-time">
                  20 Adebiyi Street, off lawani Street, Akoka Yaba
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
