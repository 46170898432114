import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/alt-logo.png";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row gap-lg-0 gap-3">
          <div className="col-lg-4">
            <div>
              <img width={150} src={Logo} alt="onebody-alt-logo" />
            </div>
            <address>
              <span>20 Adebiyi Street, off lawani Street, Akoka Yaba.</span>
            </address>
          </div>

          <div className="col-lg-4">
            <div className="row ">
              <div className="col footer-link-wrapper">
                <p className="fw-bold">Resources</p>
                <div>
                  <a rel="noreferrer" target="_blank" href="https://music.onebodychurchng.com">
                    Onebody Music
                  </a>
                </div>
                <div>
                  <a rel="noreferrer" target="_blank" href="https://youtube.com/@onebodymedia?si=hjh07K6d2p89OKXp">
                    Onebody TV Media
                  </a>
                </div>
                <div>
                  <a rel="noreferrer" target="_blank" href="https://podcasters.spotify.com/pod/show/onebody-church/">
                    Onebody Podcast{" "}
                  </a>
                </div>
              </div>
              <div className="col footer-link-wrapper">
                <p className="fw-bold">Pages</p>
                <div>
                  <Link to="/about">About</Link>
                </div>
                <div>
                  <Link to="/lords-table">Lord's Table </Link>
                </div>
                <div>
                  {/* <Link to="partner">Partner With Us </Link> */}
                </div>
                <div>
                  <Link to="/love-vision">LOVA</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
           <div>
              <iframe
                title="Onebody address map "
                width="100%"
                height="240"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.06389777501!2d3.3818864758935683!3d6.513596793478787!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8d00003626ed%3A0x75409fd6902f04d7!2sOnebody%20Church!5e0!3m2!1sen!2sng!4v1732053001587!5m2!1sen!2sng"
              >
                <a href="https://www.maps.ie/distance-area-calculator.html">measure area map</a>
              </iframe>
            </div>
          </div>
        </div>
        <div className="text-center">Copyright &copy; {new Date().getFullYear()} Onebody Church</div>
      </div>
    </footer>
  );
};

export default Footer;
