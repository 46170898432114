import React from "react";
import { Link } from "react-router-dom";
import Icon from "../Icon";
// import Img from "../../assets/images/lova.png";

const LOVA = () => {
  const Img = `https://i.ibb.co/4sjLYNB/ND2-A5027-1.jpg`
  return (
    <section className="py-3">
      <div className="container">
        <h3 className="section-title mb-3">The Love Vision Academy</h3>

        <div style={{ backgroundImage: `url(${Img})` }} className="lova-img-frame"></div>

        <p className="mt-3">
          The Love Vision Academy by Onebody was created to intimate Christians with God’s vision for his church. We believe you will get the chance
          to equip yourself with adequate Christ and Kingdom centered knowledge that will propel you to more meaningful and purposeful service.
        </p>

        <div className="">
          <Link className="section-link" to="#">
            Read more <Icon icon="upRight" />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default LOVA;
