import React from "react";
import Marquee from "react-easy-marquee";
import AnimatedText from "react-animated-text-content";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Aste from "../../assets/images/Asterisk.svg";


const About1 = `https://i.ibb.co/Msht8HV/ND2A4979.jpg`
const About2 = `https://i.ibb.co/zxm0LJv/ND2-A8526-copy.jpg`
const About3 = `https://i.ibb.co/gDSXc0t/ND2A1103.jpg`
const About4 = `https://i.ibb.co/cQQFjLc/ND2A1098.jpg`
const About5 = `https://i.ibb.co/HDMvQVd/ND2-A8484-copy.jpg`

const imgApi = [{ img: About1 }, { img: About2 }, { img: About3 }, { img: About4 }, { img: About5 }];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 2000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1700 },
    items: 4,
  },
  lg: {
    breakpoint: { max: 1700, min: 1200 },
    items: 3,
  },

  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const AboutHero = () => {
  return (
    <section>
      <header>
        <Marquee className="py-lg-5 py-0" duration="10000" align="center" reverse="true">
          <div className="services-marque-header">
            <div className="d-flex align-items-center justify-content-evenly">
              <img className="rotate" src={Aste} alt="" />
              <span>About Onebody</span>
            </div>
          </div>

          <div className="services-marque-header">
            <div className="d-flex align-items-center justify-content-evenly">
              <img className="rotate" src={Aste} alt="" />
              <span>About Onebody</span>
            </div>
          </div>
        </Marquee>
      </header>

      <div className="row mb-lg-5 mb-3 mx-0 justify-content-center">
        <div className="col-lg-6  col-12">
          <AnimatedText
            type="words" // animate words or chars
            animation={{
              x: "200px",
              y: "-20px",
              scale: 1.1,
              ease: "ease-in-out",
            }}
            animationType="float"
            interval={0.06}
            duration={0.1}
            tag="p"
            className="animated-paragraph text-center"
            includeWhiteSpaces
            threshold={0.1}
          >
            We are a church that believes in and teaches Jesus Christ. Here, Jesus is our conversation, culture, aspiration, and our everything. You
            will find Jesus here!
          </AnimatedText>
        </div>
      </div>

      <Carousel responsive={responsive} infinite={true} autoPlay={true}>
        {imgApi.map((val, key) => {
          return <div key={key} style={{ backgroundImage: `url(${val.img})` }} className="about-carousel-img"></div>;
        })}
      </Carousel>
    </section>
  );
};

export default AboutHero;
