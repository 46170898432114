import React from "react";
import Marquee from "react-easy-marquee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const LBanner = `https://i.ibb.co/4sjLYNB/ND2-A5027-1.jpg`

const SectionOne = () => {
  return (
    <section>
      <header>
        <Marquee className="py-lg-5 py-0" duration="10000" align="center">
          <div className="services-marque-header">
            <FontAwesomeIcon className="rotate mx-3" icon={faAsterisk} /> Love
            Vision
          </div>

          <div className="services-marque-header">
            <FontAwesomeIcon className="rotate mx-3" icon={faAsterisk} /> Love
            Vision
          </div>
        </Marquee>
      </header>

      <div className="py-3">
        <div className="container">
          <p style={{textAlign:"center"}}>
            <strong>Discover God’s Vision for His Church with The Love Vision Academy</strong> Hosted by Onebody Church
          </p>
        </div>
      </div>

      <div
        style={{ backgroundImage: `url(${LBanner})` }}
        className="lova-banner"
      ></div>
    </section>
  );
};

export default SectionOne;
