const SectionThree = () => {
  const openRegForm = () => {
    window.open(
      `https://bit.ly/m/LOVA`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <section className="pb-md-5 pb-3">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div>
              <p>
                Through this journey, you’ll explore practical, gospel-centered
                ways to relate with others – neighbours, strangers, or fellow
                believers – in ways that reflect God’s love and teachings. The
                ultimate goal is to inspire you to embody the values of Christ’s
                church and live out your faith with intention and purpose.{" "}
              </p>

              <p>
                At the heart of this program is a shared desire to understand
                and obey God’s Word. Together, we will explore what matters most
                to our Lord Jesus Christ and commit ourselves to living by His
                commands.
              </p>

              <p>
                <strong>Join The Love Vision Academy</strong> <br />
                Click{" "}
                <strong
                  style={{ cursor: "pointer" }}
                  className="pri-color"
                  onClick={openRegForm}
                >
                  HERE
                </strong>{" "}
                to register and become part of a community passionate about
                bringing God’s vision for His Church to life.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-iframe-wrapper mx-auto">
              <iframe
                src="https://www.youtube.com/embed/9_-54uv7Rv4"
                title="Love Vision Academy Testimonial - Jennifer Asomie"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SectionThree;
