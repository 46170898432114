import React from "react";

const SectionOne = () => {
  return (
    <section className="py-lg-5 py-3">
      <div className="container">
        <div className="row gap-lg-0 gap-2">
          <div className="col-lg-5">
            <div className="section-title">History</div>
          </div>
          <div className="col-lg-7">
            <p>
            Onebody Church opened its doors in May 2019 in Yaba, Lagos because a group of young Christians who are passionate about gospel-centred music and charity wanted to grow deeper in their knowledge of Jesus.
            </p>
            <p>
            It was founded by three friends: Taiwo Oyemade, Kehinde Oyemade, and Olarewaju Omomeji, and their families and a few friends. They met at home every friday for about three years before the church opened. All that time, they wrote and released gospel music albums as well as organized concerts, retreats and charity events in and around Lagos.
            </p>
            <p>
            What started as a Christian band going around sharing about the love of Jesus evolved into a church where disciples are being raised.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionOne;
