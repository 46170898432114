import React from "react";

const SectionTwo = () => {
  return (
    <section className="py-lg-5 py-3">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-5">
            <div className="mb-2">
              <h2 className="section-title">our vision</h2>
              <p>
                To transform people in the cities of Nigeria into agents of renewal through the love of Jesus.
              </p>
            </div>

            <div className="mb-2"> 
              <h2 className="section-title">our mission</h2>
              <p>
                To build a community of disciples of Jesus through teaching, spiritual music and city-wide missions.
              </p>
            </div>

            <div>
              <h2 className="section-title">Core Values</h2>
              <p>
                Love is changing our hearts, lighting our eyes, moving hands and leading our feets.
              </p>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="about-iframe-wrapper mx-auto">
              <iframe
                src="https://www.youtube.com/embed/Tp8Qa_5WRpQ" 
                title="A JOURNEY OF DISCOVERY // ONEBODY CHURCH"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionTwo;
