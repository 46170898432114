import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const inspirationData = [
  {
    text: "HEART - Love, changing hearts (1 John 4:9-10) : This means that the first thing we hope everyone in our community will experience is a change of heart through a deeper understanding of the Love of God revealed in the gospel of Jesus.",
  },

  {
    text: "EYES – Love, lighting eyes (1 John 2:10-11) : This means that we uphold love as a means of inspirationfor everything they begin to do. We expect to see a growth in virtuies such as forgiveness, agreement, and forbearance as indicators of a life inspired by the light of the gospel.",
  },

  {
    text: "HAND - Love, moving hands (Acts 20:35, 1 Cor 13:3) : This means that wer are constantly growing into a generous community and our sharing is driven by deep love for the lord, one another and the world around us. we would see this through sharing in welfare of the church and through charity work in our local community. "
  },
  {
    text: "FEET - Love, leading feets (2 Cor 5:14-15) : Practically, this means that we will see people who are committed to going out and 'seeking' others through organizing worship events, one-to-one street evangelism and social media content creation."
  },
];

const churchWeSeeData = [
  {
    text: "SEEKING - This is outreach through every facet. From gospel music and visual content creation to affinity groups, to special events, we are seeking new souls.",
  },
  {
    text: "CONVERTING - In our weekly gospel-centered church meetings and home meetings, we are always creating opportunities for conversions and decisions for God.",
  },
  {
    text: "ASSIMILATING - We have created an 8-module, 16-week-long discipleship program called “The Love Vision Academy” through which we assimilate new members of our church. Participants can already begin to participate in non-leadership roles in our community.",
  },
  {
    text: "DEPLOYING - We want every disciple we make to go out and seek new souls through the grace and gifts the Holy Spirit has released upon them. They can find opportunities not only for service but to explore their specific spiritual calling to city ministry.",
  },
];

const Inspiration = () => {
  return (
    <section className="py-lg-5 py-3">
      <div className="container">
        <div className="row gap-lg-0 gap-5">
          <div className="col-lg-6">
            <div className="section-title">Measuring Transaformation</div>
            <p>Core value statement: Love is changing our hearts, lighting eyes, moving hands and leading feet.</p>

            <VerticalTimeline layout="1-column-left">
              {inspirationData.map((data, key) => {
                return (
                  <VerticalTimelineElement
                    key={key}
                    className="vertical-timeline-element--work about-timeline"
                    contentStyle={{ background: "none", color: "#fff" }}
                    contentArrowStyle={{ border: "none" }}
                    iconStyle={{ background: "#fff", color: "#fff" }}
                  >
                    <p>{data.text}</p>
                  </VerticalTimelineElement>
                );
              })}
            </VerticalTimeline>
          </div>
          <div className="col-lg-6">
            <div className="section-title">renewed discipleship (SCAD)</div>
            <p>Therefore go and make disciples of all nations...” burns in our hearts. We have a refined our discipleship model with the acronym SCAD - Seeking, Converting, Assimilating, Deploying.</p>
            <VerticalTimeline layout="1-column-left">
              {churchWeSeeData.map((data, key) => {
                return (
                  <VerticalTimelineElement
                    key={key}
                    className="vertical-timeline-element--work about-timeline"
                    contentStyle={{ background: "none", color: "#fff" }}
                    contentArrowStyle={{ border: "none" }}
                    iconStyle={{ background: "#fff", color: "#fff" }}
                  >
                    <p>{data.text}</p>
                  </VerticalTimelineElement>
                );
              })}
            </VerticalTimeline>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Inspiration;
